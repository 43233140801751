@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

ul {
  list-style-type: none; }

a {
  color: inherit;
  text-decoration: none; }

ul,
ol,
div {
  margin: 0;
  padding: 0; }

html {
  font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", sans-serif;
  color: #1f1f1f;
  font-size: 10px;
  -webkit-font-feature-settings: 'palt';
          font-feature-settings: 'palt';
  letter-spacing: 0.1em;
  max-width: 1920px;
  margin: 0 auto;
  position: relative; }
  @media screen and (max-width: 980px) {
    html {
      min-width: 980px; } }
  @media screen and (max-width: 767px) {
    html {
      min-width: 100%; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    overflow-x: hidden; } }

.overflow {
  overflow: hidden; }

.image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center; }
  .image--100 {
    -o-object-fit: 100% 100%;
       object-fit: 100% 100%; }

.lowerpage-section__container {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  max-width: 1200px; }

.lowerpage-section--about-nisoken {
  padding: 80px 0 125px; }

.lowerpage-section--about-learn {
  height: 585px;
  background: url("/src/img/bg-about-learn.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media screen and (max-width: 767px) {
    .lowerpage-section--about-learn {
      padding: 40px 0;
      height: auto; } }

.lowerpage-section--about-benefit {
  padding: 90px 0 100px;
  background: #edf1f4; }

.number-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 28px;
  border-right: 2px solid #e60012;
  width: 56px;
  min-width: 56px;
  height: 70px; }
  .number-item span {
    font-size: 24px;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 700;
    color: #e60012; }
  .number-item--small {
    height: 48px; }

.header {
  background-color: #ececec;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9; }
  .header.fixed {
    position: fixed;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
  .header__container, .header__menu-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .header__container {
    width: 100%;
    height: 71.89474px;
    max-width: 1920px;
    padding: 0 40px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    margin: 0 auto; }
    @media screen and (max-width: 767px) {
      .header__container {
        height: 50px;
        padding: 0 17.5px; } }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .header__container {
        padding: 0 20px; } }
  .header__logo img {
    width: 266.01053px;
    height: auto; }
    @media screen and (max-width: 767px) {
      .header__logo img {
        width: 182.5px; } }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .header__logo img {
        width: 180px; } }
  @media screen and (max-width: 767px) {
    .header__menu-list {
      opacity: 0;
      width: 100%;
      height: 0;
      overflow: hidden;
      -webkit-transition: 500ms all ease;
      -o-transition: 500ms all ease;
      transition: 500ms all ease;
      background-color: #ececec;
      position: absolute;
      top: 50px;
      left: 0;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      padding: 50px 0 50px; } }
  @media screen and (max-width: 767px) {
    .header__menu-list.active {
      opacity: 1;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      height: 100vh;
      overflow-y: auto; } }
  .header__menu-link {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    font-size: 14px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease; }
    @media screen and (max-width: 767px) {
      .header__menu-link {
        font-size: 20px; } }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .header__menu-link {
        font-size: 13px;
        line-height: 18px; } }
    .header__menu-link.active, .header__menu-link:hover {
      color: #d71618; }
  @media screen and (max-width: 767px) {
    .header__menu-item {
      text-align: center; } }
  .header__menu-item:not(:last-child) {
    margin-right: 40px; }
    @media screen and (max-width: 767px) {
      .header__menu-item:not(:last-child) {
        margin: 0 0 50px 0; } }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .header__menu-item:not(:last-child) {
        margin-right: 25px; } }
  .header__menu-item:nth-child(6) {
    margin-right: 0; }
  .header__menu-item--contact {
    display: none; }
    @media screen and (max-width: 767px) {
      .header__menu-item--contact {
        max-width: 315px;
        width: 82%;
        height: 60px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        background-color: #ececec;
        border: 1px solid #767676;
        margin: 0 auto; } }
    @media screen and (max-width: 767px) {
      .header__menu-item--contact:not(:last-child) {
        margin: 0 auto 10px; } }
    @media screen and (max-width: 767px) {
      .header__menu-item--contact .header__menu-link {
        width: 100%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center; } }
  .header__menu-item--fb {
    display: none; }
    @media screen and (max-width: 767px) {
      .header__menu-item--fb {
        display: block;
        margin: 35px 0 50px; } }
    @media screen and (max-width: 767px) {
      .header__menu-item--fb .header__menu-link img.fb {
        width: 30px;
        height: 30px; } }
  @media screen and (max-width: 767px) {
    .header__contact-img {
      width: 25px;
      height: 25px;
      border-radius: 12.5px;
      margin-right: 10px;
      background-color: #d71618;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  @media screen and (max-width: 767px) {
    .header__contact-img img {
      height: auto; } }
  @media screen and (max-width: 767px) {
    .header__contact-img img.phone {
      width: 11.5px; } }
  @media screen and (max-width: 767px) {
    .header__contact-img img.mail {
      width: 12.5px; } }
  .header__hamburger {
    display: none; }
    @media screen and (max-width: 767px) {
      .header__hamburger {
        width: 25px;
        height: 17.5px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        position: relative;
        cursor: pointer;
        -webkit-transition: 100ms ease;
        -o-transition: 100ms ease;
        transition: 100ms ease; } }
    @media screen and (max-width: 767px) {
      .header__hamburger span {
        width: 20px;
        height: 2.5px;
        background-color: #000;
        position: absolute; } }
    @media screen and (max-width: 767px) {
      .header__hamburger span:nth-child(1) {
        top: 0;
        right: 0; } }
    @media screen and (max-width: 767px) {
      .header__hamburger span:nth-child(2) {
        top: 50%;
        right: 2.5px;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%); } }
    @media screen and (max-width: 767px) {
      .header__hamburger span:nth-child(3) {
        left: 0;
        bottom: 0; } }
    @media screen and (max-width: 767px) {
      .header__hamburger.close {
        width: 17px;
        height: 17px; } }
    @media screen and (max-width: 767px) {
      .header__hamburger.close span {
        width: 20px;
        height: 2.5px; } }
    @media screen and (max-width: 767px) {
      .header__hamburger.close span:nth-child(1) {
        -webkit-transform: translate(3px, 6px) rotate(45deg);
            -ms-transform: translate(3px, 6px) rotate(45deg);
                transform: translate(3px, 6px) rotate(45deg); } }
    @media screen and (max-width: 767px) {
      .header__hamburger.close span:nth-child(2) {
        opacity: 0; } }
    @media screen and (max-width: 767px) {
      .header__hamburger.close span:nth-child(3) {
        -webkit-transform: translate(-1px, -8px) rotate(-45deg);
            -ms-transform: translate(-1px, -8px) rotate(-45deg);
                transform: translate(-1px, -8px) rotate(-45deg); } }
  .header__submenu-list {
    width: 100%;
    height: 122.22105px;
    background-color: #d71618;
    position: absolute;
    top: 71.89474px;
    left: 0;
    display: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease; }
    @media screen and (max-width: 767px) {
      .header__submenu-list {
        position: relative;
        top: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
            -ms-flex-align: start;
                align-items: flex-start;
        height: auto;
        padding: 50px 0;
        margin-top: 30px; } }
    .header__submenu-list.active {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
  .header__submenu-item {
    position: relative; }
    @media screen and (max-width: 767px) {
      .header__submenu-item {
        padding-left: 30px; } }
    .header__submenu-item::before {
      content: '';
      width: 25.16316px;
      height: 32.35263px;
      background: url("/src/img/icon-white-arrow.png") no-repeat;
      background-size: cover;
      position: absolute;
      top: 50%;
      right: -43.13684px;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%); }
      @media screen and (max-width: 767px) {
        .header__submenu-item::before {
          width: 27.5px;
          height: 35px; } }
    .header__submenu-item:not(:last-child) {
      margin-right: 107.84211px; }
      @media screen and (max-width: 767px) {
        .header__submenu-item:not(:last-child) {
          margin: 0 0 50px 0; } }
  .header__sublink {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .header__sublink {
        font-size: 15px; } }

.button {
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  font-size: 17.25474px;
  position: relative;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  padding-right: 68.3px; }
  @media screen and (max-width: 767px) {
    .button {
      font-size: 12px;
      padding-right: 47.5px; } }
  .button::before {
    content: '';
    width: 39.54211px;
    height: 50.32632px;
    background: url("/src/img/icon-black-arrow.png") no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease; }
    @media screen and (max-width: 767px) {
      .button::before {
        width: 27.5px;
        height: 37.5px; } }
  .button:hover {
    color: #d71618; }
    .button:hover::before {
      right: -20px; }
  .button--white {
    color: #fff; }
    .button--white::before {
      background: url("/src/img/icon-white-arrow.png") no-repeat;
      background-size: contain; }
  .button__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; }
    .button__wrapper--end {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; }
    .button__wrapper--event {
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
          -ms-flex-align: end;
              align-items: flex-end; }
      @media screen and (max-width: 767px) {
        .button__wrapper--event {
          -webkit-box-align: start;
          -webkit-align-items: flex-start;
              -ms-flex-align: start;
                  align-items: flex-start; } }

.main-visual__container {
  max-width: 1920px;
  width: 100%;
  display: block;
  margin: 0 auto;
  position: relative; }

.main-visual__image img {
  width: 100%;
  height: auto; }

.main-visual__content {
  position: absolute;
  top: 294.76842px;
  left: 71.89474px; }
  @media screen and (max-width: 767px) {
    .main-visual__content {
      top: 200px;
      left: 30px; } }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .main-visual__content {
      top: 200px; } }
  .main-visual__content p {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 800;
    font-size: 57.51579px;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .main-visual__content p {
        font-size: 50px;
        line-height: 70px; } }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .main-visual__content p {
        font-size: 50px; } }

.section--event {
  padding: 90px 0 70px; }
  @media screen and (max-width: 767px) {
    .section--event {
      padding: 75px 0 65px; } }

.section--support {
  padding: 75px 0 70px; }
  @media screen and (max-width: 767px) {
    .section--support {
      padding: 65px 0 90px; } }

.section--activity {
  padding: 222.87368px 0 136.6px; }
  @media screen and (max-width: 767px) {
    .section--activity {
      padding: 290px 20px 85px; } }

.section--enrollment {
  padding-bottom: 143.78947px; }
  @media screen and (max-width: 767px) {
    .section--enrollment {
      padding: 0 20px 100px; } }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .section--enrollment {
      padding: 0 40px 100px; } }

.section__wrapper {
  max-width: 1920px;
  width: 100%;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  background-color: #edf1f4; }
  .section__wrapper::before {
    content: '';
    max-width: 1380.37895px;
    width: 100%;
    height: 913.06316px;
    background: url("/src/img/deco-white01.png") no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
  .section__wrapper--deco::before {
    width: 1056.85263px;
    height: 539.21053px;
    background: url("/src/img/deco-white02.png") no-repeat;
    background-position: center;
    background-size: cover; }
    @media screen and (max-width: 767px) {
      .section__wrapper--deco::before {
        width: 100%; } }

.section__title.text-center {
  text-align: center; }

.section__title h3 {
  font-family: noto-sans-cjk-jp, sans-serif;
  font-size: 34.50947px;
  font-weight: 800;
  letter-spacing: 0.1em;
  line-height: 41.69895px; }
  @media screen and (max-width: 767px) {
    .section__title h3 {
      font-size: 40px;
      line-height: 50px; } }

.section__title span {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
  font-size: 11.50316px;
  color: #d71618;
  line-height: 36px; }
  @media screen and (max-width: 767px) {
    .section__title span {
      font-size: 13px;
      line-height: 36px; } }

.section__title--white h3 {
  color: #fff; }

.event__container {
  max-width: 1160px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .event__container {
      max-width: 100%;
      padding: 0 20px; } }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .event__container {
      max-width: 100%;
      padding: 0 40px; } }
  @media screen and (max-width: 767px) {
    .event__container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media screen and (max-width: 767px) {
    .event__container a.margin {
      margin-bottom: 55px; } }
  .event__container--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }

@media screen and (max-width: 767px) {
  .event__header {
    margin-bottom: 50px; } }

.event__header .section__title {
  margin-bottom: 86.27368px; }
  @media screen and (max-width: 767px) {
    .event__header .section__title {
      margin-bottom: 0; } }

.event__header a.margin {
  margin-bottom: 79.08421px; }

.event__header--flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%; }

.event__image {
  overflow: hidden;
  width: 100px;
  height: 100px; }
  .event__image .img {
    width: 100%;
    height: 100%;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center; }
    @media screen and (max-width: 767px) {
      .event__image .img {
        width: 75px; } }
  @media screen and (max-width: 767px) {
    .event__image--auto img {
      width: 100%; } }

.event__link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease; }
  .event__link:hover {
    color: #d71618; }
    .event__link:hover .event__image img {
      -webkit-transform: scale(1.2);
          -ms-transform: scale(1.2);
              transform: scale(1.2); }

.event__list {
  width: 680px; }
  @media screen and (max-width: 767px) {
    .event__list {
      width: 100%;
      margin-bottom: 60px; } }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .event__list {
      width: 63%; } }
  .event__list--flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
    margin-bottom: 93.46316px; }
    @media screen and (max-width: 767px) {
      .event__list--flex {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
    .event__list--flex .event__link {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start; }
      @media screen and (max-width: 767px) {
        .event__list--flex .event__link {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          -webkit-flex-direction: row;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
              -ms-flex-pack: justify;
                  justify-content: space-between; } }

.event .event__item--col {
  width: 320px; }
  @media screen and (max-width: 767px) {
    .event .event__item--col {
      width: 100%; } }
  .event .event__item--col:not(:last-child) {
    margin: 0 100px 0 0 !important; }
    @media screen and (max-width: 767px) {
      .event .event__item--col:not(:last-child) {
        margin: 0 0 30px 0 !important; } }

.event .event__item:not(:last-child) {
  margin-bottom: 28.75789px; }

.event__content {
  width: 540px; }
  @media screen and (max-width: 767px) {
    .event__content {
      width: 65%; } }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .event__content {
      width: 80%; } }
  .event__content--full {
    width: 100%; }
    @media screen and (max-width: 767px) {
      .event__content--full {
        width: 65%; } }
    .event__content--full .event__wrap {
      margin: 28.75789px 0 14.37895px; }
      @media screen and (max-width: 767px) {
        .event__content--full .event__wrap {
          margin: 0; } }

.event .event__wrap {
  margin-bottom: 14.37895px; }
  @media screen and (max-width: 767px) {
    .event .event__wrap {
      margin-bottom: 10px; } }

.event__date {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 500;
  color: #818181;
  margin-right: 14.37895px; }

.event__category {
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  color: #d71618; }

.event__date, .event__category {
  font-size: 12px; }

.event__title {
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  font-size: 17.25474px; }
  @media screen and (max-width: 767px) {
    .event__title {
      font-size: 15px; } }

.support__container {
  max-width: 1160px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  @media screen and (max-width: 767px) {
    .support__container {
      max-width: 100%;
      padding: 0 20px; } }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .support__container {
      max-width: 100%;
      padding: 0 40px; } }
  @media screen and (max-width: 767px) {
    .support__container {
      padding: 0; } }

.support__item {
  width: 359.47368px; }
  @media screen and (max-width: 767px) {
    .support__item {
      width: 250px;
      height: 290px;
      margin: 0 16.5px !important; } }
  .support__item:not(:last-child) {
    margin-right: 76.92737px; }
    @media screen and (max-width: 767px) {
      .support__item:not(:last-child) {
        margin-right: 0; } }

.support__content {
  margin-top: 28.75789px; }
  @media screen and (max-width: 767px) {
    .support__content {
      margin-top: 30px; } }

.support__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 57.51579px 0 71.89474px; }
  @media screen and (max-width: 767px) {
    .support__list {
      margin: 57.5px 0 65px; } }
  @media screen and (max-width: 767px) {
    .support__list--activity {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  @media screen and (max-width: 767px) {
    .support__list--activity .support__item {
      width: 100%;
      height: auto;
      margin: 0 !important; } }
  @media screen and (max-width: 767px) {
    .support__list--activity .support__item:not(:last-child) {
      margin-bottom: 40px !important; } }
  @media screen and (max-width: 767px) {
    .support__list--activity .support__content {
      margin-top: 20px; } }

.support__image {
  width: 335px;
  height: 235px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .support__image {
      width: 100%;
      height: auto; } }
  .support__image .img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }

.support__link {
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease; }
  .support__link:hover {
    color: #d71618; }
    .support__link:hover .support__image img {
      -webkit-transform: scale(1.2);
          -ms-transform: scale(1.2);
              transform: scale(1.2); }

.support__category {
  width: 104.24737px;
  height: 28.75789px;
  background-color: #d71618;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 14.37895px; }
  @media screen and (max-width: 767px) {
    .support__category {
      padding: 0 10px;
      margin-bottom: 15px; } }
  .support__category--green {
    background-color: #00e1b4; }
  .support__category span {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    font-size: 10.06526px;
    color: #fff; }

.support__title {
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  font-size: 17.25474px; }
  @media screen and (max-width: 767px) {
    .support__title {
      font-size: 15px;
      line-height: 25px; } }

.company {
  background-color: #d71618;
  max-width: 1920px;
  width: 100%;
  height: 783.65263px;
  display: block;
  margin: 0 auto;
  position: relative; }
  @media screen and (max-width: 767px) {
    .company {
      height: auto; } }
  .company__container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 0 auto;
    z-index: 1; }
    @media screen and (max-width: 767px) {
      .company__container {
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-right: 0; } }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .company__container {
        width: 100%; } }
  .company__content {
    position: relative;
    z-index: 1;
    padding: 136.6px 0 0 71.89474px; }
    @media screen and (max-width: 767px) {
      .company__content {
        height: auto;
        padding: 70px 20px; } }
    .company__content::before {
      content: '';
      width: 71.4vw;
      height: 783.65263px;
      background: url("/src/img/company-image.png") no-repeat;
      background-size: cover;
      background-position: center right;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1; }
      @media screen and (max-width: 767px) {
        .company__content::before {
          width: 100%;
          height: 100%;
          background: url("/src/img/company-image-sp.jpg") no-repeat;
          background-size: cover; } }
      @media screen and (max-width: 1024px) and (min-width: 768px) {
        .company__content::before {
          width: 750px;
          background-position: right; } }
  .company__wrapper {
    margin-top: 100.65263px; }
    @media screen and (max-width: 767px) {
      .company__wrapper {
        margin-top: 75px; } }
  .company__label, .company__heading, .company__subtext {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 800;
    color: #fff; }
  .company__label {
    font-size: 11.50316px;
    width: 143.78947px;
    height: 28.75789px;
    border: 1px solid #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media screen and (max-width: 767px) {
      .company__label {
        font-size: 13px; } }
  .company__heading {
    font-size: 57.51579px;
    margin: 21.56842px 0 7.18947px;
    line-height: 58px; }
    @media screen and (max-width: 767px) {
      .company__heading {
        font-size: 60px;
        margin: 20px 0; } }
    .company__heading--sm {
      font-size: 28.75789px; }
      @media screen and (max-width: 767px) {
        .company__heading--sm {
          line-height: 40px; } }
  .company__subtext {
    font-size: 16px;
    line-height: 41.69895px; }
    @media screen and (max-width: 767px) {
      .company__subtext {
        font-size: 13px;
        line-height: 29px; } }
  .company__menu-list {
    width: 359.47368px;
    margin-top: 287.57895px; }
    @media screen and (max-width: 767px) {
      .company__menu-list {
        width: 100%;
        margin: 60px 0;
        padding-left: 75px; } }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .company__menu-list {
        width: 25%; } }
    @media screen and (max-width: 320px) {
      .company__menu-list--margin {
        margin-bottom: 350px !important; } }
    @media screen and (max-width: 767px) {
      .company__menu-list--margin {
        margin-bottom: 295px; } }
  .company__menu-item:not(:last-child) {
    margin-bottom: 61.11053px; }
    @media screen and (max-width: 767px) {
      .company__menu-item:not(:last-child) {
        margin-bottom: 50px; } }
  .company__menu-link {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 17.25474px;
    font-weight: 700;
    color: #fff;
    position: relative;
    padding-right: 68.3px; }
    @media screen and (max-width: 767px) {
      .company__menu-link {
        font-size: 15px;
        padding-right: 47.5px; } }
    .company__menu-link::before {
      content: '';
      width: 39.54211px;
      height: 50.32632px;
      background: url("/src/img/icon-white-arrow.png") no-repeat;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-transition: 0.3s all ease;
      -o-transition: 0.3s all ease;
      transition: 0.3s all ease; }
      @media screen and (max-width: 767px) {
        .company__menu-link::before {
          width: 27.5px;
          height: 37.5px;
          right: -47.5px; } }
    .company__menu-link:hover::before {
      right: -20px; }

.enrollment {
  max-width: 1160px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  background-color: #000;
  padding: 57.51579px 71.89474px;
  z-index: 1; }
  @media screen and (max-width: 767px) {
    .enrollment {
      max-width: 100%;
      padding: 0 20px; } }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .enrollment {
      max-width: 100%;
      padding: 0 40px; } }
  @media screen and (max-width: 767px) {
    .enrollment {
      padding: 30px; } }
  @media screen and (max-width: 1024px) and (min-width: 768px) {
    .enrollment {
      padding: 30px; } }
  .enrollment.absolute {
    position: absolute;
    left: 50%;
    bottom: -136.6px;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%); }
    @media screen and (max-width: 767px) {
      .enrollment.absolute {
        width: 85%;
        bottom: -220px; } }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .enrollment.absolute {
        width: 80%; } }
  .enrollment__header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .enrollment__content {
    margin-top: 35.94737px; }
    @media screen and (max-width: 767px) {
      .enrollment__content {
        margin-bottom: 40px; } }
    .enrollment__content p {
      font-family: noto-sans-cjk-jp, sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      line-height: 36px; }
      @media screen and (max-width: 767px) {
        .enrollment__content p {
          font-size: 13px;
          line-height: 28px; } }

.inquiries {
  max-width: 1920px;
  width: 100%;
  height: 442.15263px;
  background: url("/src/img/inquiries-image.jpg") no-repeat;
  background-size: cover;
  display: block;
  margin: 0 auto;
  padding-top: 100.65263px; }
  @media screen and (max-width: 767px) {
    .inquiries {
      height: 477.5px;
      padding: 60px 20px 0; } }
  .inquiries__container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .inquiries__header {
    text-align: center; }
    @media screen and (max-width: 767px) {
      .inquiries__header {
        text-align: left; } }
    .inquiries__header h4,
    .inquiries__header p {
      font-family: noto-sans-cjk-jp, sans-serif;
      font-weight: 800;
      color: #fff; }
    .inquiries__header h4 {
      font-size: 34.50947px;
      line-height: 58px; }
      @media screen and (max-width: 767px) {
        .inquiries__header h4 {
          font-size: 30px;
          line-height: 45px; } }
    .inquiries__header p {
      font-size: 17.25474px; }
      @media screen and (max-width: 767px) {
        .inquiries__header p {
          font-size: 12px;
          line-height: 29px; } }
  .inquiries__button-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 43.13684px; }
    @media screen and (max-width: 767px) {
      .inquiries__button-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        margin-top: 40px; } }
  .inquiries__button {
    width: 402.61053px;
    height: 107.84211px;
    background-color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease; }
    @media screen and (max-width: 767px) {
      .inquiries__button {
        width: 100%;
        height: 75px; } }
    .inquiries__button:nth-child(1) {
      margin-right: 28.75789px; }
      @media screen and (max-width: 767px) {
        .inquiries__button:nth-child(1) {
          margin: 0 0 20px 0; } }
    .inquiries__button:hover {
      background-color: #000; }
    .inquiries__button span {
      font-family: noto-sans-cjk-jp, sans-serif;
      font-weight: 700;
      font-size: 25.88211px;
      color: #fff; }
      @media screen and (max-width: 767px) {
        .inquiries__button span {
          font-size: 20px; } }
  .inquiries__contact-img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #d71618;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-right: 20px; }
    @media screen and (max-width: 767px) {
      .inquiries__contact-img {
        width: 30px;
        height: 30px;
        border-radius: 15px; } }
    .inquiries__contact-img img {
      width: auto;
      height: auto; }
      @media screen and (max-width: 767px) {
        .inquiries__contact-img img {
          width: 60%; } }

.footer {
  width: 100%;
  padding: 71.89474px 0 43.13684px;
  background-color: #ececec; }
  @media screen and (max-width: 767px) {
    .footer {
      padding: 50px 0 30px; } }
  .footer__container {
    max-width: 1160px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 70px; }
    @media screen and (max-width: 767px) {
      .footer__container {
        max-width: 100%;
        padding: 0 20px; } }
    @media screen and (max-width: 1024px) and (min-width: 768px) {
      .footer__container {
        max-width: 100%;
        padding: 0 40px; } }
    @media screen and (max-width: 767px) {
      .footer__container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .footer__logo {
    text-align: center; }
    .footer__logo img {
      width: 266.01053px;
      height: auto; }
      @media screen and (max-width: 767px) {
        .footer__logo img {
          width: 185px; } }
  .footer__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    @media screen and (max-width: 767px) {
      .footer__content {
        margin-top: 50px; } }
  .footer__menu-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
    .footer__menu-list--flex {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
          -ms-flex-direction: row;
              flex-direction: row; }
      .footer__menu-list--flex .menu__link {
        margin-bottom: 20px !important; }
        @media screen and (max-width: 767px) {
          .footer__menu-list--flex .menu__link {
            margin-bottom: 20px !important; } }
      .footer__menu-list--flex:not(:last-child) {
        margin-right: 0 !important; }
      .footer__menu-list--flex a:not(:last-child) {
        margin-right: 80px; }
        @media screen and (max-width: 767px) {
          .footer__menu-list--flex a:not(:last-child) {
            margin-right: 60px; } }
    .footer__menu-list:not(:last-child) {
      margin-right: 60px; }
  .footer__menu-link {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 400;
    font-size: 12px;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease; }
    @media screen and (max-width: 767px) {
      .footer__menu-link {
        font-size: 10px; } }
    .footer__menu-link:not(:last-child) {
      margin-bottom: 20px; }
      @media screen and (max-width: 767px) {
        .footer__menu-link:not(:last-child) {
          margin-bottom: 15px; } }
    .footer__menu-link:hover {
      color: #d71618; }
    .footer__menu-link.bold {
      font-weight: 700; }
    .footer__menu-link img {
      width: 30px;
      height: 30px; }
    .footer__menu-link img.small {
      width: auto;
      height: auto;
      margin-left: 10px; }
      @media screen and (max-width: 767px) {
        .footer__menu-link img.small {
          margin-left: 5px;
          width: 8px; } }
  .footer__copyright {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
    .footer__copyright p {
      font-family: "Noto Sans JP", sans-serif;
      font-weight: 500;
      font-size: 12px; }
      @media screen and (max-width: 767px) {
        .footer__copyright p {
          font-size: 9px; } }

.mvlower {
  margin-top: 70px;
  position: relative;
  background-color: #e60012;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .mvlower {
      margin-top: 50px; } }
  .mvlower__bg {
    width: 82%;
    height: auto;
    margin-bottom: -5px; }
    @media screen and (max-width: 767px) {
      .mvlower__bg {
        width: 110%; } }
  .mvlower__main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0 auto;
    width: auto;
    min-width: 1200px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
    @media screen and (min-width: 1201px) {
      .mvlower__main {
        left: calc((100vw - 1200px) / 2); } }
    @media screen and (min-width: 1921px) {
      .mvlower__main {
        left: calc((1920px - 1200px) / 2); } }
    @media screen and (max-width: 767px) {
      .mvlower__main {
        position: relative;
        padding: 10px 20px; } }
  .mvlower__title {
    font-size: 60px;
    font-family: noto-sans-cjk-jp, sans-serif;
    line-height: 1.8;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .mvlower__title {
        font-size: 30px; } }
  .mvlower__subtitle {
    position: relative;
    padding-left: 10px;
    font-size: 16px;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 500;
    color: #e60012; }
    @media screen and (max-width: 767px) {
      .mvlower__subtitle {
        color: #fff;
        font-size: 14px; } }
    .mvlower__subtitle::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 5px;
      height: 2px;
      background: #e60012;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%); }
      @media screen and (max-width: 767px) {
        .mvlower__subtitle::before {
          background-color: #fff; } }

.breadcrumb {
  padding: 20px 0;
  width: 100%; }
  .breadcrumb__list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    max-width: 1200px; }
  .breadcrumb__item {
    font-size: 12px;
    font-family: noto-sans-cjk-jp, sans-serif; }
    @media screen and (max-width: 767px) {
      .breadcrumb__item {
        white-space: nowrap;
        -o-text-overflow: ellipsis;
           text-overflow: ellipsis;
        overflow: hidden; } }
    .breadcrumb__item:not(:first-child) {
      position: relative;
      margin-left: 10px;
      padding-left: 14px; }
      .breadcrumb__item:not(:first-child)::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 5px;
        height: 8px;
        background: url("/src/img/icon-breadcrumb.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%); }

.about {
  padding-bottom: 150px;
  width: 100%; }
  .about__section-subtitle {
    font-size: 24px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    line-height: 58px; }
    @media screen and (max-width: 767px) {
      .about__section-subtitle {
        font-size: 20px; } }
    .about__section-subtitle--white {
      color: #fff; }
  .about__nisoken-title {
    font-size: 48px;
    line-height: 58px; }
    @media screen and (max-width: 767px) {
      .about__nisoken-title {
        font-size: 32px;
        line-height: normal; } }
  .about__nisoken-subheading {
    margin: 70px 0 55px;
    border: 1px solid #e60012;
    padding: 21px 0;
    width: 370px;
    font-size: 24px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    line-height: 36px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .about__nisoken-subheading {
        margin: 40px 0 50px;
        width: 100%; } }
  .about__nisoken-text {
    font-size: 16px;
    line-height: 36px;
    font-family: noto-sans-cjk-jp, sans-serif;
    letter-spacing: 2px; }
    .about__nisoken-text:not(:first-child) {
      margin-top: 40px; }
      @media screen and (max-width: 767px) {
        .about__nisoken-text:not(:first-child) {
          margin-top: 25px; } }
  .about__learn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 50%;
    height: 100%;
    max-width: 580px; }
    @media screen and (max-width: 767px) {
      .about__learn {
        width: 100%;
        max-width: 100%; } }
  .about__learn-title {
    font-size: 48px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    line-height: 1.8;
    color: #fff; }
    @media screen and (max-width: 767px) {
      .about__learn-title {
        font-size: 28px; } }
  .about__learn-details {
    margin-top: 60px;
    font-size: 16px;
    font-family: noto-sans-cjk-jp, sans-serif;
    color: #fff;
    line-height: 36px;
    letter-spacing: 2px; }
    @media screen and (max-width: 767px) {
      .about__learn-details {
        margin-top: 20px; } }
  .about__benefit {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 90px; }
    @media screen and (max-width: 767px) {
      .about__benefit {
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; } }
  .about__benefit-item {
    width: 45%;
    min-width: 530px; }
    @media screen and (max-width: 767px) {
      .about__benefit-item {
        width: 100%;
        min-width: 100%; }
        .about__benefit-item:not(:first-child) {
          margin-top: 20px; } }
  .about__benefit-heading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 30px;
    border-bottom: 2px solid #e60012;
    padding-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .about__benefit-heading {
        margin-bottom: 15px;
        padding-bottom: 15px; } }
  .about__benefit-title {
    font-size: 48px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700; }
    @media screen and (max-width: 767px) {
      .about__benefit-title {
        font-size: 30px; } }
    .about__benefit-title span {
      font-size: 16px; }
      @media screen and (max-width: 767px) {
        .about__benefit-title span {
          font-size: 8px; } }
  .about__benefit-content {
    padding-left: 5px;
    font-size: 16px;
    font-family: noto-sans-cjk-jp, sans-serif;
    line-height: 36px;
    letter-spacing: 1px; }
  .about__article {
    margin-top: 24px;
    width: 100%; }
  .about__article-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    .about__article-item:not(:first-child) {
      margin-top: 30px; }
    .about__article-item--big-space:not(:first-child) {
      margin-top: 40px; }
  .about__article-item-text {
    font-size: 24px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    line-height: 34px; }
    @media screen and (max-width: 767px) {
      .about__article-item-text {
        font-size: 14px; } }
  .about__membership-section {
    padding-top: 90px;
    width: 100%; }
    .about__membership-section:last-child {
      padding-bottom: 115px; }
  .about__membership-section-title {
    margin: 0 auto 40px;
    font-size: 48px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: bold;
    line-height: 58px;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .about__membership-section-title {
        font-size: 38px; } }
  .about__membership-process {
    margin-top: 20px;
    width: 100%; }

.activity {
  margin-top: 77px;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .activity {
      margin-top: 60px; } }
  .activity__text-container {
    margin: 0 60px; }
    @media screen and (max-width: 767px) {
      .activity__text-container {
        margin: 15px auto;
        width: 100%; } }
  .activity__list:not(:first-child) {
    margin-top: 60px; }
    @media screen and (max-width: 767px) {
      .activity__list:not(:first-child) {
        margin-top: 37.5px; } }
  .activity__list:last-child {
    margin-bottom: 94px; }
    @media screen and (max-width: 767px) {
      .activity__list:last-child {
        margin-bottom: 45px; } }
  .activity__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    @media screen and (max-width: 767px) {
      .activity__item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .activity__thumbnail {
    height: 200px;
    width: 200px; }
    @media screen and (max-width: 767px) {
      .activity__thumbnail {
        height: auto;
        width: 100%; } }
  .activity__paragraph {
    font-size: 16px;
    font-family: noto-sans-cjk-jp, sans-serif;
    letter-spacing: 0.1em;
    line-height: 2.25em;
    text-align: justify; }
    @media screen and (max-width: 767px) {
      .activity__paragraph {
        font-size: 12px; } }
  .activity__date {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: #818181; }
    @media screen and (max-width: 767px) {
      .activity__date {
        font-size: 12px; } }
    .activity__date--description {
      font-family: noto-sans-cjk-jp, sans-serif;
      font-weight: 700;
      color: #d71618;
      margin-left: 20px; }
      @media screen and (max-width: 767px) {
        .activity__date--description {
          margin-left: 17.5px; } }
  .activity__information {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 2.41em;
    letter-spacing: 0.1em;
    white-space: nowrap; }
    @media screen and (max-width: 767px) {
      .activity__information {
        font-size: 18px;
        white-space: pre-wrap; } }
  .activity__nav {
    height: 45px;
    width: 35px; }
    @media screen and (max-width: 767px) {
      .activity__nav {
        height: auto;
        width: 7%;
        margin-left: auto; } }
  @media screen and (max-width: 767px) {
    .activity__break {
      display: none; } }

.activity-more {
  margin-top: 72px;
  margin-bottom: 60px;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media screen and (max-width: 767px) {
    .activity-more {
      margin-top: 20px;
      margin-bottom: 40px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }
  .activity-more__container {
    width: 740px; }
    @media screen and (max-width: 767px) {
      .activity-more__container {
        width: 100%; } }
  .activity-more__image {
    margin: 65px auto 80px;
    width: 740px; }
    @media screen and (max-width: 767px) {
      .activity-more__image {
        margin: 37.5px auto 30px;
        width: 100%; } }
  .activity-more__paragraph {
    font-size: 16px;
    font-family: noto-sans-cjk-jp, sans-serif;
    letter-spacing: 0.21em;
    line-height: 2.25em;
    text-align: justify; }
    @media screen and (max-width: 767px) {
      .activity-more__paragraph {
        font-size: 12px; } }
    .activity-more__paragraph:last-child {
      margin-bottom: 102px; }
      @media screen and (max-width: 767px) {
        .activity-more__paragraph:last-child {
          margin-bottom: 40px; } }
  .activity-more__title {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.83em;
    letter-spacing: 0.1em;
    margin-top: 52px;
    border-bottom: 1px solid #d71618; }
    @media screen and (max-width: 767px) {
      .activity-more__title {
        font-size: 16px;
        margin-top: 40px; } }
    .activity-more__title::before {
      content: '\25A0';
      position: relative;
      font-size: 18px;
      margin-right: 11px;
      top: -1px;
      left: -2px;
      color: #d71618; }
      @media screen and (max-width: 767px) {
        .activity-more__title::before {
          font-size: 12px;
          margin-right: 6px;
          top: -1.5px;
          left: 0; } }
  .activity-more__date {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.1em;
    color: #818181; }
    @media screen and (max-width: 767px) {
      .activity-more__date {
        font-size: 12px; } }
    .activity-more__date--description {
      font-family: noto-sans-cjk-jp, sans-serif;
      font-weight: 700;
      color: #d71618;
      margin-left: 20px; }
      @media screen and (max-width: 767px) {
        .activity-more__date--description {
          margin-left: 12.5px; } }
  .activity-more__sidenav {
    margin-left: 97px;
    margin-top: -16px;
    width: 319px; }
    @media screen and (max-width: 767px) {
      .activity-more__sidenav {
        height: auto;
        width: 100%;
        margin-left: 0;
        margin-top: 0; } }
  .activity-more__sidenav-title {
    font-size: 16px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    line-height: 3.36em;
    letter-spacing: 0.1em;
    border-bottom: 1px solid #d71618; }
    @media screen and (max-width: 767px) {
      .activity-more__sidenav-title {
        font-size: 16px; } }
  .activity-more__sidenav-thumbnail {
    height: 80px;
    width: 80px; }
    @media screen and (max-width: 767px) {
      .activity-more__sidenav-thumbnail {
        height: auto;
        width: 30%; } }
  .activity-more__sidenav-list {
    margin-top: 40px; }
    @media screen and (max-width: 767px) {
      .activity-more__sidenav-list {
        margin-top: 30px; } }
  .activity-more__sidenav-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .activity-more__sidenav-text {
    margin-left: 21px; }
    @media screen and (max-width: 767px) {
      .activity-more__sidenav-text {
        margin-left: 20px; } }
  .activity-more__sidenav-information {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 2em;
    letter-spacing: 0.1em; }
    @media screen and (max-width: 767px) {
      .activity-more__sidenav-information {
        font-size: 12px; } }
  .activity-more__pagenav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 0; }
    @media screen and (max-width: 767px) {
      .activity-more__pagenav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }
  .activity-more__pagenav-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    @media screen and (max-width: 767px) {
      .activity-more__pagenav-item {
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        padding-bottom: 20px; } }
    .activity-more__pagenav-item:not(:first-child) {
      border-left: 2px solid #ececec;
      padding-left: 47px; }
      @media screen and (max-width: 767px) {
        .activity-more__pagenav-item:not(:first-child) {
          border-left: none;
          padding-left: 0; } }
  .activity-more__pagenav-text {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding-right: 47px; }
    @media screen and (max-width: 767px) {
      .activity-more__pagenav-text {
        padding-right: 0; } }
  .activity-more__pagenav-image {
    position: relative;
    height: 80px;
    width: 80px;
    margin-right: 21px; }
    @media screen and (max-width: 767px) {
      .activity-more__pagenav-image {
        height: 30%;
        width: 30%;
        margin-right: 20px; } }
  .activity-more__pagenav-arrow {
    position: absolute;
    top: -72px;
    left: -160px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #d71618;
    letter-spacing: 0.1em;
    line-height: 3.36em;
    width: 100%;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .activity-more__pagenav-arrow {
        text-align: left;
        font-size: 12px;
        top: 40px;
        left: 25px; } }
    .activity-more__pagenav-arrow::before {
      content: '';
      position: absolute;
      left: 59px;
      width: 9px;
      height: 15px;
      bottom: 50%;
      -webkit-transform: translateY(55%) rotate(180deg);
          -ms-transform: translateY(55%) rotate(180deg);
              transform: translateY(55%) rotate(180deg);
      background-image: url("/src/img/button-arrow.png");
      background-size: 100%;
      background-repeat: no-repeat; }
      @media screen and (max-width: 767px) {
        .activity-more__pagenav-arrow::before {
          -webkit-transform: translateY(55%) rotate(180deg);
              -ms-transform: translateY(55%) rotate(180deg);
                  transform: translateY(55%) rotate(180deg);
          left: -24px; } }
    .activity-more__pagenav-arrow--next {
      top: -72px;
      left: 152px;
      text-align: left; }
      @media screen and (max-width: 767px) {
        .activity-more__pagenav-arrow--next {
          top: 40px;
          left: 0; } }
      .activity-more__pagenav-arrow--next::before {
        content: '';
        position: absolute;
        left: 88px;
        width: 9px;
        height: 15px;
        bottom: 50%;
        -webkit-transform: translateY(55%);
            -ms-transform: translateY(55%);
                transform: translateY(55%);
        background-image: url("/src/img/button-arrow.png");
        background-size: 100%;
        background-repeat: no-repeat; }
        @media screen and (max-width: 767px) {
          .activity-more__pagenav-arrow--next::before {
            left: 67.5px;
            -webkit-transform: translateY(55%);
                -ms-transform: translateY(55%);
                    transform: translateY(55%); } }

.privacypolicy {
  margin-top: 65px; }
  @media screen and (max-width: 767px) {
    .privacypolicy {
      margin-top: 25px; } }
  .privacypolicy__text {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 16px;
    line-height: 2.25em;
    letter-spacing: 0.1em;
    text-align: justify; }
    @media screen and (max-width: 767px) {
      .privacypolicy__text {
        font-size: 12px; } }
    .privacypolicy__text--list {
      list-style-type: none;
      padding-left: 5px; }
      @media screen and (max-width: 767px) {
        .privacypolicy__text--list {
          padding-left: 0; } }
      .privacypolicy__text--list::before {
        content: "•";
        font-size: 15px;
        padding-right: 5px; }
        @media screen and (max-width: 767px) {
          .privacypolicy__text--list::before {
            padding-right: 5px; } }
  .privacypolicy__list {
    margin-bottom: 86px; }
    @media screen and (max-width: 767px) {
      .privacypolicy__list {
        margin-bottom: 60px; } }
  .privacypolicy__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 49px;
    margin-bottom: 9px; }
    @media screen and (max-width: 767px) {
      .privacypolicy__item {
        margin-top: 25px;
        margin-bottom: 25px; } }
  .privacypolicy__title {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.42em;
    letter-spacing: 0.1em; }
    @media screen and (max-width: 767px) {
      .privacypolicy__title {
        font-size: 18px; } }

.message {
  position: relative;
  margin: 101px 0 152px; }
  @media screen and (max-width: 767px) {
    .message {
      margin: 30px 0 45px; } }
  .message__wrapper {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 79px;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .message__wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
        margin-top: 0; } }
  .message__main-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .message__main-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse; } }
  .message__title {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.21em;
    letter-spacing: 0.1em;
    margin-top: -32px; }
    @media screen and (max-width: 767px) {
      .message__title {
        font-size: 32px;
        margin: 20px 0; } }
    .message__title--smaller {
      margin-top: 0;
      font-size: 16px;
      line-height: 3.63em; }
    .message__title--medium {
      margin-top: 0;
      font-weight: 700; }
      @media screen and (max-width: 767px) {
        .message__title--medium {
          font-size: 18px;
          margin: 0; } }
    .message__title--bigger {
      font-size: 80px;
      line-height: 0.73em;
      margin-top: 0; }
      @media screen and (max-width: 767px) {
        .message__title--bigger {
          font-size: 45px;
          text-align: center;
          margin-top: 10px; } }
  .message__title-wrap {
    margin-top: 23px;
    margin-left: 60px; }
    @media screen and (max-width: 767px) {
      .message__title-wrap {
        margin: auto; } }
  .message__description {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 3.63em;
    letter-spacing: 0.1em;
    margin-top: 25px; }
    @media screen and (max-width: 767px) {
      .message__description {
        font-size: 12px;
        line-height: 2em;
        margin-top: 15px; } }
  .message__box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: 180px;
    height: 180px;
    border: 1px solid #000; }
    @media screen and (max-width: 767px) {
      .message__box {
        margin: 20px auto; } }
  .message__text-box {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 1.42em;
    letter-spacing: 0.1em;
    text-align: center;
    padding: 61px 25px; }
  .message__image-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: -50px 0 21px; }
    @media screen and (max-width: 767px) {
      .message__image-wrap {
        margin: 10px auto; } }
  .message__image {
    height: 350px;
    width: 350px; }
    @media screen and (max-width: 767px) {
      .message__image {
        width: 100%;
        height: auto; } }
  .message__details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    background-color: #edf1f4;
    padding: 80px 0; }
  .message__details-box {
    background-color: #fff;
    width: 1161px;
    border-top: 4px solid #e60012;
    padding: 100px; }
    @media screen and (max-width: 767px) {
      .message__details-box {
        width: 100%;
        padding: 20px; } }
  .message__details-text {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 16px;
    line-height: 2.25em;
    text-align: justify; }
    @media screen and (max-width: 767px) {
      .message__details-text {
        font-size: 12px; } }
    .message__details-text:not(:last-child) {
      margin-bottom: 58px; }
      @media screen and (max-width: 767px) {
        .message__details-text:not(:last-child) {
          margin-bottom: 35px; } }
  .message__list-container {
    margin-top: 122px; }
    @media screen and (max-width: 767px) {
      .message__list-container {
        margin-top: 30px; } }
  .message__list {
    display: block;
    margin-top: 60px; }
    @media screen and (max-width: 767px) {
      .message__list {
        margin-top: 45px; } }
  .message__list-item {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 580px;
    margin-bottom: 40px; }
    @media screen and (max-width: 767px) {
      .message__list-item {
        min-width: 100%; } }
  .message__list-details {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    margin: 12px 0 6px;
    padding-bottom: 25px;
    border-bottom: 2px solid #d71618; }
    @media screen and (max-width: 767px) {
      .message__list-details {
        padding-bottom: 15px; } }
  .message__list-details-container {
    margin: 47px 0 140px; }
    @media screen and (max-width: 767px) {
      .message__list-details-container {
        margin: 25px 0 75px; } }
  .message__list-title {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.42em;
    letter-spacing: 0.1em; }
    @media screen and (max-width: 767px) {
      .message__list-title {
        font-size: 18px; } }

.pagenav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  width: 96%; }
  @media screen and (max-width: 767px) {
    .pagenav {
      padding: 0 5%;
      width: 100%;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
  .pagenav__item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 33.3%;
    height: 100px; }
    @media screen and (max-width: 767px) {
      .pagenav__item {
        border-left: 2px solid #e60012;
        padding-left: 30px;
        width: 100%; } }
    .pagenav__item::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 40px;
      width: 56px;
      height: 70px;
      background: url("/src/img/icon-black-arrow.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%); }
      @media screen and (max-width: 767px) {
        .pagenav__item::after {
          right: 0; } }
    .pagenav__item:not(:first-child) {
      border-left: 2px solid #e60012;
      padding-left: 30px; }
      @media screen and (max-width: 767px) {
        .pagenav__item:not(:first-child) {
          margin-top: 10px; } }
    .pagenav__item:last-child::after {
      right: 0; }
  .pagenav__item-title {
    font-size: 38px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700; }
  .pagenav__item-subtitle {
    position: relative;
    padding-left: 14px;
    font-size: 16px;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 500;
    line-height: 36px;
    color: #e60012; }
    .pagenav__item-subtitle::before {
      content: '';
      position: absolute;
      top: 56%;
      left: 0;
      width: 5px;
      height: 2px;
      background: #e60012;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%); }

.member-support {
  padding: 80px 0 150px; }
  @media screen and (max-width: 767px) {
    .member-support {
      padding: 40px 5% 75px; } }
  .member-support__articles {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    max-width: 1210px; }
  .member-support__articles-item {
    margin: 0 25px 60px;
    width: 353px; }
    @media screen and (max-width: 767px) {
      .member-support__articles-item {
        margin: 0 0 60px;
        width: 100%; } }
  .member-support__articles-item-details {
    margin-top: 30px;
    width: 100%; }
  .member-support__articles-item-category {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .member-support__articles-item-title {
    margin-top: 10px;
    font-size: 16.95px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    line-height: 25.43px; }
  .member-support__details {
    margin: 0 auto;
    width: 100%;
    max-width: 1160px; }
  .member-support__details-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 25.43px; }
  .member-support__details-categories {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 30px 0 82px; }
    @media screen and (max-width: 767px) {
      .member-support__details-categories {
        margin: 30px 0 52px; } }
  .member-support__details-image {
    width: 100%;
    height: 800px;
    background: #00e1b4; }
    @media screen and (max-width: 767px) {
      .member-support__details-image {
        height: 300px; } }
  .member-support__details-content {
    margin: 70px 0 100px; }
    @media screen and (max-width: 767px) {
      .member-support__details-content {
        margin: 52px 0 70px; } }
  .member-support__details-nav {
    position: relative;
    margin-top: 90px;
    width: 100%;
    height: 120px; }
    @media screen and (max-width: 767px) {
      .member-support__details-nav {
        height: auto; } }
  .member-support__details-nav-item {
    width: auto; }
    @media screen and (max-width: 767px) {
      .member-support__details-nav-item {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        position: static !important;
        width: 100%; } }
    .member-support__details-nav-item--prev {
      position: absolute;
      top: 0;
      left: 0; }
      .member-support__details-nav-item--prev .member-support__details-nav-direction {
        position: relative;
        padding-left: 30px; }
        .member-support__details-nav-item--prev .member-support__details-nav-direction::before {
          content: '';
          position: absolute;
          top: 55%;
          left: 0;
          width: 9px;
          height: 16px;
          background: url("/src/img/button-arrow.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          -webkit-transform: translateY(-50%) rotate(180deg);
              -ms-transform: translateY(-50%) rotate(180deg);
                  transform: translateY(-50%) rotate(180deg); }
    .member-support__details-nav-item--next {
      position: absolute;
      top: 0;
      right: 0; }
      .member-support__details-nav-item--next .member-support__details-nav-direction {
        position: relative;
        padding-right: 30px;
        text-align: right; }
        .member-support__details-nav-item--next .member-support__details-nav-direction::before {
          content: '';
          position: absolute;
          top: 55%;
          right: 0;
          width: 9px;
          height: 16px;
          background: url("/src/img/button-arrow.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
                  transform: translateY(-50%); }
    @media screen and (max-width: 767px) {
      .member-support__details-nav-item:not(:first-child) {
        margin-top: 20px; } }
  .member-support__details-nav-direction {
    font-size: 16px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    color: #d71618; }
  .member-support__details-nav-details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 20px; }
    @media screen and (max-width: 767px) {
      .member-support__details-nav-details {
        margin-top: 10px; } }
  .member-support__details-nav-image {
    width: 120px;
    height: 84px; }
    @media screen and (max-width: 767px) {
      .member-support__details-nav-image {
        width: 30%;
        height: auto; } }
  .member-support__details-nav-info {
    margin-left: 20px;
    width: 300px; }
    @media screen and (max-width: 767px) {
      .member-support__details-nav-info {
        width: 65%; } }
  .member-support__details-nav-category {
    font-size: 12px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    color: #00e1b4; }
  .member-support__details-nav-title {
    font-size: 16px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    line-height: 26px;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .member-support__image {
    width: 100%;
    height: 245px; }
  .member-support__category {
    padding: 6.5px 14px;
    font-size: 9.89px;
    font-weight: 700;
    color: #fff;
    background: #00e1b4;
    line-height: 25.43px; }
    .member-support__category--details {
      padding: 11.5px 17.5px;
      font-size: 14px; }
  .member-support__thecontent {
    font-size: 16px;
    font-family: noto-sans-cjk-jp, sans-serif;
    line-height: 36px; }
  .member-support__addarticle {
    width: 100%; }
    .member-support__addarticle:not(:first-child) {
      margin-top: 50px; }
  .member-support__addarticle-title {
    position: relative;
    padding: 0 0 10px 30px;
    font-size: 24px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .member-support__addarticle-title::before {
      content: '';
      position: absolute;
      top: 14px;
      left: 0;
      width: 12px;
      height: 12px;
      background: #d71618; }
    .member-support__addarticle-title::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #d71618; }
  .member-support__addarticle-content {
    margin-top: 20px;
    font-size: 16px;
    font-family: noto-sans-cjk-jp, sans-serif;
    line-height: 36px; }
  .member-support .wp-pagenavi {
    margin: 0 auto !important; }

.schedule {
  padding: 100px 0 150px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
      -ms-flex-align: end;
          align-items: flex-end; }
  @media screen and (max-width: 767px) {
    .schedule {
      padding: 30px 0 75px; } }
  .schedule > iframe {
    width: 100%;
    overflow-x: scroll; }
    @media screen and (max-width: 767px) {
      .schedule > iframe {
        height: 500px; } }
  .schedule__link {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-top: 60px;
    color: #000;
    letter-spacing: 0.1em;
    font-size: 24px;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 700; }
    @media screen and (max-width: 767px) {
      .schedule__link {
        font-size: 18px;
        margin-top: 30px; } }
    .schedule__link > img {
      width: 60px;
      height: auto;
      margin-left: 45px; }
      @media screen and (max-width: 767px) {
        .schedule__link > img {
          width: 45px;
          margin-left: 15px; } }

.enrollment-page__container {
  margin: 0 auto;
  width: 100%;
  max-width: 1160px; }
  @media screen and (max-width: 767px) {
    .enrollment-page__container {
      padding: 0 5%; } }

.enrollment-page__section {
  padding: 47px 0;
  width: 100%; }
  .enrollment-page__section:last-child {
    padding-bottom: 100px; }
  .enrollment-page__section--fees {
    margin: 47px 0;
    padding: 60px 0 70px;
    background: #edf1f4; }
  .enrollment-page__section--flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
    @media screen and (max-width: 767px) {
      .enrollment-page__section--flex {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column; } }

.enrollment-page__title {
  font-size: 48px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  line-height: 58px; }
  @media screen and (max-width: 767px) {
    .enrollment-page__title {
      font-size: 34px;
      line-height: normal; } }
  .enrollment-page__title--types {
    position: relative;
    margin: 0 auto;
    padding-left: 48px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 24px;
    color: #d71618; }
    .enrollment-page__title--types::before {
      content: '';
      position: absolute;
      top: 54%;
      left: 0;
      width: 30px;
      height: 24px;
      background: url("/src/img/icon-member-type.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%); }
  .enrollment-page__title--fees {
    position: relative;
    margin: 0 auto;
    padding-left: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 24px;
    color: #d71618; }
    .enrollment-page__title--fees::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 30px;
      height: 26px;
      background: url("/src/img/icon-piggybank.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%); }

.enrollment-page__content {
  margin-top: 75px; }
  @media screen and (max-width: 767px) {
    .enrollment-page__content {
      margin-top: 25px; } }
  .enrollment-page__content--flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .enrollment-page__content--flex {
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; } }
  .enrollment-page__content--fees {
    margin: 58px auto 0;
    width: 72.8%; }
    @media screen and (max-width: 767px) {
      .enrollment-page__content--fees {
        margin: 24px auto 0;
        width: 90%; } }

.enrollment-page__list {
  width: 100%; }

.enrollment-page__list-item {
  position: relative;
  padding-left: 30px;
  font-size: 24px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  line-height: 54px; }
  @media screen and (max-width: 767px) {
    .enrollment-page__list-item {
      font-size: 18px;
      line-height: normal; }
      .enrollment-page__list-item:not(:first-child) {
        margin-top: 15px; } }
  .enrollment-page__list-item::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    background: #d71618;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }

.enrollment-page__text {
  font-size: 16px;
  font-family: noto-sans-cjk-jp, sans-serif;
  line-height: 36px; }
  .enrollment-page__text span {
    font-weight: 700; }
  .enrollment-page__text--list {
    margin: 35px 0 80px; }
    @media screen and (max-width: 767px) {
      .enrollment-page__text--list {
        margin: 15px 0 40px; } }
  .enrollment-page__text--member {
    letter-spacing: 3px; }
  .enrollment-page__text--fees {
    margin-top: 40px; }
    @media screen and (max-width: 767px) {
      .enrollment-page__text--fees {
        margin-top: 20px; } }
  .enrollment-page__text--documents {
    margin: 35px 0 40px; }

.enrollment-page__member-type {
  width: 45.6%; }
  @media screen and (max-width: 767px) {
    .enrollment-page__member-type {
      width: 100%; }
      .enrollment-page__member-type:not(:first-child) {
        margin-top: 20px; } }

.enrollment-page__member-type-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 35px;
  border-bottom: 2px solid #d71618;
  padding-bottom: 30px; }

.enrollment-page__member-type-title {
  font-size: 48px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  line-height: 68px; }

.enrollment-page__fees {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .enrollment-page__fees {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column; } }

.enrollment-page__fees-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 767px) {
    .enrollment-page__fees-item {
      width: 100%; }
      .enrollment-page__fees-item:not(:first-child) {
        margin-top: 15px; } }

.enrollment-page__fees-label {
  position: relative;
  margin-right: 30px;
  padding-right: 39px;
  font-size: 24px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  color: #d71618; }
  @media screen and (max-width: 767px) {
    .enrollment-page__fees-label {
      margin-right: 24px;
      padding-right: 24px;
      font-size: 18px; } }
  .enrollment-page__fees-label::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 2px;
    height: 48px;
    background: #d71618;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }

.enrollment-page__fees-price {
  font-size: 48px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: bold;
  line-height: 0; }
  @media screen and (max-width: 767px) {
    .enrollment-page__fees-price {
      font-size: 32px;
      line-height: normal; } }
  .enrollment-page__fees-price span {
    font-size: 28px; }

.enrollment-page__admission {
  margin-left: 130px; }
  @media screen and (max-width: 767px) {
    .enrollment-page__admission {
      margin-top: 30px;
      margin-left: 0; } }

.enrollment-page__admission-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  @media screen and (max-width: 767px) {
    .enrollment-page__admission-item {
      padding-left: 0 !important; } }
  .enrollment-page__admission-item:not(:first-child) {
    margin-top: 20px; }
    .enrollment-page__admission-item:not(:first-child) .enrollment-page__admission-number {
      position: relative; }
      .enrollment-page__admission-item:not(:first-child) .enrollment-page__admission-number::before {
        content: '';
        position: absolute;
        top: -30px;
        left: 10%;
        width: 4px;
        height: 40px;
        background: #d71618;
        -webkit-transform: translateX(-50%) rotate(-30deg);
            -ms-transform: translateX(-50%) rotate(-30deg);
                transform: translateX(-50%) rotate(-30deg); }
        @media screen and (max-width: 767px) {
          .enrollment-page__admission-item:not(:first-child) .enrollment-page__admission-number::before {
            left: 50%;
            -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                    transform: translateX(-50%); } }
  .enrollment-page__admission-item--2 {
    padding-left: 40px; }
  .enrollment-page__admission-item--3 {
    padding-left: 80px; }
  .enrollment-page__admission-item--4 {
    padding-left: 120px; }
  .enrollment-page__admission-item--5 {
    padding-left: 160px; }

.enrollment-page__admission-number {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  margin-right: 20px;
  width: 60px;
  height: 60px;
  font-size: 24px;
  font-family: "Noto Sans JP", sans-serif;
  color: #fff;
  background: #d71618; }
  @media screen and (max-width: 767px) {
    .enrollment-page__admission-number {
      min-width: 60px;
      min-height: 60px; } }

.enrollment-page__admission-text {
  font-size: 24px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 800; }
  .enrollment-page__admission-text span {
    font-size: 16px;
    font-weight: 400; }

.enrollment-page__documents {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 60px;
  font-size: 24px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700; }
  .enrollment-page__documents::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 40px;
    height: 40px;
    background: url("/src/img/icon-download.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
  .enrollment-page__documents:not(:first-of-type) {
    margin-top: 20px; }

.enrollment-page__pagenav {
  padding: 100px 0 150px; }

.event__categories {
  margin: 0 auto;
  width: 100%;
  max-width: 1160px; }
  @media screen and (max-width: 767px) {
    .event__categories {
      padding: 5%; } }

.event__categories-text {
  font-size: 16px;
  font-family: noto-sans-cjk-jp, sans-serif; }

.event__categories-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 20px 0 85px; }
  @media screen and (max-width: 767px) {
    .event__categories-list {
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      margin: 20px 0; } }

.event__categories-item {
  position: relative;
  border: 1px solid #646464;
  padding: 7px 20px;
  min-width: 120px;
  font-size: 16px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  color: #646464;
  -webkit-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  transition: all 300ms linear;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .event__categories-item {
      margin: 0 2% 20px; } }
  .event__categories-item::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4.5px 0;
    border-color: transparent;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: all 400ms linear;
    -o-transition: all 400ms linear;
    transition: all 400ms linear; }
  .event__categories-item:not(:first-child) {
    margin-left: 10px; }
    @media screen and (max-width: 767px) {
      .event__categories-item:not(:first-child) {
        margin-left: 2%; } }
  .event__categories-item--active {
    border-color: #d71618;
    background: #d71618;
    color: #fff; }
    .event__categories-item--active::before {
      bottom: -6px;
      border-color: #d71618 transparent transparent; }
  .event__categories-item:hover {
    border-color: #d71618;
    background: #d71618;
    color: #fff; }
    .event__categories-item:hover::before {
      bottom: -6px;
      border-color: #d71618 transparent transparent; }

.member {
  margin: 70px 0 150px; }
  @media screen and (max-width: 767px) {
    .member {
      margin: 40px 0 75px; } }
  .member__title {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-weight: 800;
    font-size: 48px;
    letter-spacing: 0.1em;
    line-height: 1.21em; }
    @media screen and (max-width: 767px) {
      .member__title {
        font-size: 32px; } }
    .member__title--line {
      padding-bottom: 40px;
      border-bottom: 2px solid #e60012;
      margin-top: 90px;
      width: 100%; }
      @media screen and (max-width: 767px) {
        .member__title--line {
          padding-bottom: 15px;
          margin-top: 30px; } }
      .member__title--line:nth-child(4) {
        margin-top: 10px; }
        @media screen and (max-width: 767px) {
          .member__title--line:nth-child(4) {
            margin-top: 5px; } }
  .member__list {
    display: block;
    margin: 65px auto 138px;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .member__list {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin: 25px auto 35px; } }
  .member__item {
    display: inline-block;
    padding: 0 24px;
    background-color: #ececec; }
    @media screen and (max-width: 767px) {
      .member__item {
        padding: 0 15px;
        margin: 10px;
        text-align: center; } }
    .member__item:not(:last-child) {
      margin-right: 10px; }
      @media screen and (max-width: 767px) {
        .member__item:not(:last-child) {
          margin-right: 7px; } }
    .member__item:hover {
      background-color: #d71618;
      color: #fff;
      -webkit-transition: 0.5s ease;
      -o-transition: 0.5s ease;
      transition: 0.5s ease; }
  .member__item-text {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.1em; }
    @media screen and (max-width: 767px) {
      .member__item-text {
        font-size: 16px; } }
  .member__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin: 60px 0 0;
    width: 100%;
    max-width: 1210px; }
    @media screen and (max-width: 767px) {
      .member__content {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin: 30px auto 0;
        max-width: 100%; } }
  .member__content-item {
    margin: 0 0 40px;
    width: 530px; }
    @media screen and (max-width: 767px) {
      .member__content-item {
        margin: 0 auto 20px;
        width: 100%; } }
  .member__content-image {
    height: 321px;
    width: 530px; }
    @media screen and (max-width: 767px) {
      .member__content-image {
        height: auto;
        width: 100%; } }
  .member__content-title {
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 1.42em;
    letter-spacing: 0.1em;
    margin-top: 29px;
    text-align: justify; }
    @media screen and (max-width: 767px) {
      .member__content-title {
        margin-top: 15px;
        font-size: 16px; } }
  .member__content-details-container {
    display: block;
    margin: 40px 0; }
    @media screen and (max-width: 767px) {
      .member__content-details-container {
        margin: 20px 0; } }
  .member__content-field {
    display: inline-block;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 16px;
    line-height: 2.25em;
    letter-spacing: 0.1em;
    width: 90px; }
    @media screen and (max-width: 767px) {
      .member__content-field {
        font-size: 12px; } }
  .member__content-details {
    display: inline-block;
    font-family: noto-sans-cjk-jp, sans-serif;
    font-size: 16px;
    line-height: 2.25em;
    letter-spacing: 0.1em;
    font-weight: 700;
    border-left: 2px solid #d71618;
    padding-left: 19px;
    width: 440px;
    text-align: justify; }
    @media screen and (max-width: 767px) {
      .member__content-details {
        font-size: 12px;
        padding-left: 10px;
        width: 100%; } }
  .member__content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px; }
  .member__content-hover:hover {
    color: #e60012;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease; }

@media screen and (max-width: 767px) {
  .flickity-viewport {
    width: 100%;
    height: 290px !important; } }

@media screen and (max-width: 767px) {
  .flickity-prev-next-button {
    top: 45px;
    -webkit-transform: translate(0);
        -ms-transform: translate(0);
            transform: translate(0); } }

.wp-pagenavi {
  margin: 94px auto 150px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media screen and (max-width: 767px) {
    .wp-pagenavi {
      margin: 37.5px auto 57.5px;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }

.previouspostslink,
.nextpostslink,
.page,
.current {
  font-size: 16px;
  font-family: noto-sans-cjk-jp, sans-serif;
  font-weight: 700;
  list-style: 2.25em;
  letter-spacing: 0.1em;
  color: #d71618;
  background: #eee;
  height: 50px;
  -webkit-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  transition: 0.5s ease;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center; }
  @media screen and (max-width: 767px) {
    .previouspostslink,
    .nextpostslink,
    .page,
    .current {
      font-size: 14px;
      height: 35px; } }
  .previouspostslink:not(:last-child),
  .nextpostslink:not(:last-child),
  .page:not(:last-child),
  .current:not(:last-child) {
    margin-right: 10px; }
    @media screen and (max-width: 767px) {
      .previouspostslink:not(:last-child),
      .nextpostslink:not(:last-child),
      .page:not(:last-child),
      .current:not(:last-child) {
        margin-right: 4px; } }

.previouspostslink:hover,
.nextpostslink:hover,
.page:hover {
  background-color: #d71618;
  color: white; }
  .previouspostslink:hover::after,
  .nextpostslink:hover::after,
  .page:hover::after {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1); }

.previouspostslink,
.nextpostslink {
  position: relative;
  width: 50px; }
  @media screen and (max-width: 767px) {
    .previouspostslink,
    .nextpostslink {
      width: 35px; } }
  .previouspostslink::after,
  .nextpostslink::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 15px;
    bottom: 50%;
    -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
            transform: translateY(50%);
    background-image: url("/src/img/button-arrow.png");
    background-size: 100%;
    background-repeat: no-repeat; }
    @media screen and (max-width: 767px) {
      .previouspostslink::after,
      .nextpostslink::after {
        width: 5px;
        height: 8px; } }

.previouspostslink {
  padding-right: 15px; }
  @media screen and (max-width: 767px) {
    .previouspostslink {
      padding-right: 11.5px; } }
  .previouspostslink::after {
    right: 22px;
    -webkit-transform: translateY(50%) rotate(180deg);
        -ms-transform: translateY(50%) rotate(180deg);
            transform: translateY(50%) rotate(180deg); }
    @media screen and (max-width: 767px) {
      .previouspostslink::after {
        right: 15px; } }

.nextpostslink {
  padding-left: 15px; }
  @media screen and (max-width: 767px) {
    .nextpostslink {
      padding-left: 11.5px; } }
  .nextpostslink::after {
    left: 22px; }
    @media screen and (max-width: 767px) {
      .nextpostslink::after {
        left: 15px; } }

.page,
.current {
  width: 50px; }
  @media screen and (max-width: 767px) {
    .page,
    .current {
      width: 35px; } }

.current {
  background-color: #d71618;
  color: white; }

.mwform-checkbox-field label {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }
  .mwform-checkbox-field label input {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer; }

.mwform-checkbox-field .mwform-checkbox-field-text {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #000;
  font-family: noto-sans-cjk-jp, sans-serif; }
  @media screen and (max-width: 767px) {
    .mwform-checkbox-field .mwform-checkbox-field-text {
      font-size: 12px; } }

.contact {
  padding: 80px 0 150px;
  width: 1000px;
  margin: 0 auto; }
  @media screen and (max-width: 767px) {
    .contact {
      width: 100%;
      padding: 40px 0 75px; } }
  .contact__text {
    letter-spacing: 0.1em;
    color: 800;
    font-size: 14px;
    font-family: noto-sans-cjk-jp, sans-serif; }
    @media screen and (max-width: 767px) {
      .contact__text {
        font-size: 14px; } }
  .contact__progress {
    width: 734px;
    height: auto;
    margin: 125px auto 75px;
    display: block; }
    @media screen and (max-width: 767px) {
      .contact__progress {
        width: 100%;
        max-width: 734px;
        margin: 60px auto 30px; } }
  .contact__form {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column; }
    .contact__form-item {
      width: 100%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      @media screen and (max-width: 767px) {
        .contact__form-item {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-direction: column;
              -ms-flex-direction: column;
                  flex-direction: column; } }
      .contact__form-item:not(:first-child) {
        margin-top: 55px; }
        @media screen and (max-width: 767px) {
          .contact__form-item:not(:first-child) {
            margin-top: 25px; } }
    .contact__form-label {
      width: 24%;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      letter-spacing: 0.1em;
      line-height: 40px;
      position: relative;
      font-size: 14px;
      font-family: noto-sans-cjk-jp, sans-serif; }
      @media screen and (max-width: 767px) {
        .contact__form-label {
          width: auto;
          font-size: 12px;
          padding-right: 50px; } }
      .contact__form-label--required::after, .contact__form-label--optional::after {
        line-height: 1.71429em;
        letter-spacing: 0.1em;
        height: 30px;
        width: 50px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        position: absolute;
        right: 0;
        font-size: 14px;
        font-family: noto-sans-cjk-jp, sans-serif;
        font-weight: 700; }
        @media screen and (max-width: 767px) {
          .contact__form-label--required::after, .contact__form-label--optional::after {
            height: 25px;
            width: 41px;
            font-size: 12px; } }
      .contact__form-label--required::after {
        content: '必須';
        color: #e60012;
        border: 2px solid #e60012; }
      .contact__form-label--optional::after {
        content: '任意';
        color: #c9c9c9;
        border: 2px solid #c9c9c9; }
    .contact__form-input-wrapper {
      width: 74%;
      max-width: 737px;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
      letter-spacing: 0.1em;
      color: #000;
      line-height: 40px;
      font-size: 14px;
      font-family: noto-sans-cjk-jp, sans-serif; }
      @media screen and (max-width: 767px) {
        .contact__form-input-wrapper {
          margin-top: 10px;
          font-size: 12px;
          width: 100%;
          line-height: 2em; } }
      .contact__form-input-wrapper span.note {
        margin-top: 12px;
        letter-spacing: 0.1em;
        color: #626262;
        font-size: 14px;
        font-family: noto-sans-cjk-jp, sans-serif; }
        @media screen and (max-width: 767px) {
          .contact__form-input-wrapper span.note {
            font-size: 12px; } }
    .contact__form-input {
      height: 40px;
      padding: 10px 20px;
      border: 1px solid #626262;
      letter-spacing: 0.1em;
      color: #000;
      width: 100%;
      font-size: 14px;
      font-family: noto-sans-cjk-jp, sans-serif; }
      @media screen and (max-width: 767px) {
        .contact__form-input {
          font-size: 12px;
          padding: 10px 15px; } }
      .contact__form-input::-webkit-input-placeholder {
        color: #c9c9c9; }
      .contact__form-input:-ms-input-placeholder {
        color: #c9c9c9; }
      .contact__form-input::-ms-input-placeholder {
        color: #c9c9c9; }
      .contact__form-input::placeholder {
        color: #c9c9c9; }
      .contact__form-input--small {
        width: 61%;
        max-width: 450px;
        margin-right: 39%; }
        @media screen and (max-width: 767px) {
          .contact__form-input--small {
            width: 100%; } }
      .contact__form-input--furigana {
        width: calc(100% - 80px);
        margin-top: 12px; }
        @media screen and (max-width: 767px) {
          .contact__form-input--furigana {
            width: calc(100% - 60px);
            margin-top: 10px; } }
    .contact__form-textarea {
      width: 100%;
      height: 120px;
      padding: 10px 20px;
      resize: none;
      border: 1px solid #626262;
      letter-spacing: 0.1em;
      color: #000;
      font-size: 14px;
      font-family: noto-sans-cjk-jp, sans-serif; }
      @media screen and (max-width: 767px) {
        .contact__form-textarea {
          font-size: 12px;
          height: 200px;
          padding: 10px 15px; } }
      .contact__form-textarea::-webkit-input-placeholder {
        color: #c9c9c9; }
      .contact__form-textarea:-ms-input-placeholder {
        color: #c9c9c9; }
      .contact__form-textarea::-ms-input-placeholder {
        color: #c9c9c9; }
      .contact__form-textarea::placeholder {
        color: #c9c9c9; }
  .contact__privacy {
    width: 100%;
    height: 200px;
    border: 1px solid #c9c9c9;
    padding: 20px 40px 20px 15px;
    margin-top: 55px;
    position: relative;
    color: #000;
    font-size: 14px;
    font-family: noto-sans-cjk-jp, sans-serif; }
    @media screen and (max-width: 767px) {
      .contact__privacy {
        font-size: 12px;
        padding: 10px 40px 10px 15px;
        height: 250px; } }
    .contact__privacy::after {
      content: '';
      height: 100%;
      width: 20px;
      background-color: #c9c9c9;
      top: 0;
      right: 0;
      position: absolute; }
  .contact__checkbox-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 60px; }
  .contact__checkbox {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer; }
  .contact__checkbox, .contact__checkbox-label {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.1em;
    color: #000;
    font-family: noto-sans-cjk-jp, sans-serif; }
    @media screen and (max-width: 767px) {
      .contact__checkbox, .contact__checkbox-label {
        font-size: 12px; } }
  .contact__checkbox-label {
    margin-left: 10px; }
    @media screen and (max-width: 767px) {
      .contact__checkbox-label {
        margin-left: 7px; } }
  .contact__button-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 100px; }
    @media screen and (max-width: 767px) {
      .contact__button-wrapper {
        margin-top: 50px; } }
  .contact__button {
    width: 400px;
    height: 60px;
    background-color: #e60012;
    border: 2px solid #e60012;
    border-radius: 0;
    color: #fff;
    letter-spacing: 0.1em;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease, color 0.3s ease;
    -o-transition: background-color 0.3s ease, color 0.3s ease;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 14px;
    font-weight: 700;
    font-family: noto-sans-cjk-jp, sans-serif; }
    @media screen and (max-width: 767px) {
      .contact__button {
        width: 100%;
        max-width: 300px;
        height: 45px;
        font-size: 12px; } }
    .contact__button:hover {
      background-color: #fff;
      color: #e60012; }

.simplebar-scrollbar::before {
  background-color: #aaa;
  border-radius: 2.5px;
  right: 5px; }

.simplebar-track.simplebar-vertical {
  width: 15px; }

.mw_wp_form_confirm .note,
.mw_wp_form_confirm .contact__privacy,
.mw_wp_form_confirm .contact__checkbox-wrapper {
  display: none; }

.mw_wp_form_complete .contact__progress {
  margin-top: 0; }
  @media screen and (max-width: 767px) {
    .mw_wp_form_complete .contact__progress {
      margin-top: 0; } }

.white {
  color: #fff; }

.white-bg {
  background: #fff; }

.black {
  color: #000; }

.black-bg {
  background: #000; }

.primary {
  color: #d71618; }

.primary-bg {
  background: #d71618; }

.center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center; }

.pc {
  display: block; }
  @media screen and (max-width: 767px) {
    .pc {
      display: none; } }

.sp {
  display: none; }
  @media screen and (max-width: 767px) {
    .sp {
      display: block; } }

.pc-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  @media screen and (max-width: 767px) {
    .pc-flex {
      display: none; } }

.sp-flex {
  display: none; }
  @media screen and (max-width: 767px) {
    .sp-flex {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; } }
